.profileGrid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 3%;
    grid-row-gap: 15px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    /* margin-bottom: 20px; */
}
.commonData p{
    margin-bottom: 0;
}