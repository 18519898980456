.calenderContainer {
  width: 100%;
  /* max-width: 350px; */
}
.react-calendar {
  padding: 2px !important;
  border-radius: 1rem !important;
  overflow: visible !important;
  border: 2px solid grey;
  width: auto !important;
}
.react-calendar__navigation {
  display: flex;
  height: 30px !important;
  /* margin-bottom: 1em; */
}
.react-calendar__tile {
  overflow: visible !important;
  position: relative;
  border-radius: 8px;
}
.react-calendar__navigation {
  margin-bottom: 0 !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #120e4d;
}

.react-calendar__tile--active {
  background: #120e4d;
  color: white;
}

.tooltip-active p {
  margin: 0;
}

.tooltip-active span {
  display: none;
  position: absolute;
  top: -45px; /* Adjust the position as needed */
  left: 50%;
  transform: translateX(-50%);
  background: white;
  color: black;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  white-space: nowrap; /* Prevent tooltip text wrapping */
}

.react-calendar__tile:hover .tooltip-active span,
.react-calendar__tile--active .tooltip-active span {
  display: block;
}
/* @media screen and (max-width: 1400px) {
  .calenderContainer {
    width: 50%;
  }
}
@media screen and (max-width: 670px) {
  .calenderContainer {
    width: 100%;
  }
} */
/*   
  .tooltip-active {
    position: absolute;
    background-color: white;
    border: 1px solid black;
    padding: 5px;
    z-index: 1000;
  } */
