.spinner {
    position: absolute;
    top: 40%;
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
  }
  .spinner2 {
    position: absolute;
    top: 60%;
    width: 56px;
    height: 56px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
  }
  .spinner2::before,
  .spinner2::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  .spinner2::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  .spinner2::after {
    margin: 8.9px;
  }
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .spinner::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .spinner::after {
    margin: 8.9px;
  }
  
  @keyframes spinner-e04l1k {
    100% {
      transform: rotate(1turn);
    }
  }
  .css-qz9rsj-MuiAutocomplete-root {
    width: 100%;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    margin-top: -10px !important;
}
  .p-inputotp{
    display: flex;
    justify-content: space-between;
  }
  .p-inputotp-input{
    height: 40px !important;
    border: 2px solid grey;
    background: #ead8f9;
  }
  .noTableData{
    width: 250px !important;
    height: 250px !important;
  }
 .Tablesearch{
    height: 40px;
    width: 30%;
    min-width: 130px;
    /* vertical-align: bottom;/ */
    align-self: flex-end;
 }
.leftcontainer{
    width: 70%;
    padding-right: 0;
}
.rightContainer{
    width: 30%;
    flex-direction: column;
    /* margin-top: 68px; */
    margin-right: 20px;
}
.textHead p{
    margin: 0;
}
.documentContainer{
    width: 100%;
}
.documentContainer p{
    gap: 15px;
}
.semi_container{
    transition: 0.6s ease-in-out;
}
.notificationBtn .dot{
    right: 118px;
}
.profileImage{
    margin-right: 40px;
}
.textHead{
    flex-direction: column;
    justify-content: center;
    align-items: start; 
    padding: 10px 5px;
    width: 70%;
}
.headerText{
    font-weight: 600;
    font-size: clamp(12px, 1.1vw, 15px);
}
.col{
    height: 60px;
    gap: 20px;
    width: 31%;
    border-radius: 7px;
}
/* .row{
    gap: 20px;
    flex-wrap: nowrap;
} */
.mainIcon{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    /* background-color: #eaedff; */
}
.IconParent{
    width: 30%;
    justify-content: end;
    align-items: center;
}
/* .tbConatiner{
    max-height: 430px;
} */
.accordion-body a:hover{
    border: none !important;
}
.accordion-body a{
    border: none !important;
}
.MuiStack-root{
    overflow: hidden !important;
}
.MuiInputBase-root{
    height: 35px !important;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root{
    font-size: 0.9rem !important;
}
@media screen and (max-width:1400px) {
    .semi_container{
        flex-wrap: wrap;
    }
    
    .documentContainer{
        width: 50%;
        margin: 0 0 0 25px;

    }
    .noteContainer{
        width: 100%;
    }
    .noteParent{
        width: -webkit-fill-available;
    }
    
}
@media screen and (max-width:1300px) {
    .firstHide{
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    .secHide{
        display: none;
    }
    .rightContainer{
        margin-right: 0;
    }
}
@media screen and (max-width:1000px){
    /* .leftcontainer{
        padding-right: 20px;
    } */
    .notificationBtn .dot{
        right: 90px;
    }
    .profileImage{
        margin-right: 20px;
    }
    .leftcontainer{
        width: 100%;
        padding-right: 16px;
    }
    .rightContainer{
        width: 100%;
        justify-content: start;
        flex-direction: row-reverse;
        gap: 0;
        margin-top: 0;
        padding-top: 0 !important;
        padding-right: 20px;
    }
}
@media screen and (max-width: 767px) {
    .LeftDropdown{
        flex-direction: column-reverse;
    }
    .thirdHide{
        display: none   ;
    }
    .tableContainer{
        min-height: calc(100vh - 70px) !important;
        border-radius: 0 !important;
        border: none !important;
    }
    .imgDiv{
        height: 100px !important;
        width: 100px !important;
    }
}
@media screen and (max-width:670px) {
    .rightContainer{
        flex-direction: column-reverse;
        padding: 0 16px;
        justify-content: center;
        align-items: center;
    }
    .documentContainer, .noteParent{
        width: 100%;
        margin: 0;
    }
}
@media screen and (max-width:600px) {
    .Tablesearch{
        height: 30px;
    }
    .headerText{
        display: none;
    }
    .textHead{
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding-right: 15px;
    }
    .col{
        height: 40px;
        gap: 10px;
        /* min-width: 100px; */
        border-radius: 8px;
        max-width: 300px;
    }
    .row{
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .mainIcon{
        height: 20px;
        width: 20px;
        border-radius: 50%;
        /* background-color: #eaedff; */
    }
    .IconParent{
        width: 50%;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
    }
    .rightSide button{
        display: none;
    }
    .centerSide{
        display: none;
    }
    .headerText{
        width: 70%;
    }
    .documentContainer p{
        gap: 20px;
    } 
    .tbConatiner{
        max-height: 100%;
    }  
}
/* @media screen and (max-width: 400px) {
    .dateFilterContainer{
        flex-wrap: wrap;
     }
     .endDate, .startDate{
        width: 100%;
     }
} */