.addEmpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.image-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 200px;
    justify-content: space-around;
}

.selected-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
}

.selected-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px dashed #ccc;
    border-radius: 50%;
    background-color: #f8f8f8;
    cursor: pointer;
    width: 150px;
    height: 150px;
    justify-content: center;
}

.upload-icon-container {
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.p-toast-message-content{
  display: flex !important;
  gap: 10px !important;
  align-items: center !important;
  padding: 10px !important;
  max-width: 400px;
  /* justify-content: center; */
}
.p-toast-summary{
  font-size: clamp(12px, 1.2vw, 15px);
}
.p-toast-detail{
  font-size: clamp(10px, 1.1vw, 13px);
}
.css-19kzrtu{
  padding: 0 !important;
}
.arrow-up-icon {
  font-size: 24px;
}

.upload-image-container span {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.upload-image-container p {
  color: #777;
  font-size: 14px;
  margin-bottom: 1rem;
}

.select-image-button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
}

.pen-icon {
  margin-right: 0.5rem;
}
.SingleData p{
    margin-bottom: 0;
    font-size: 12px;
}
.SingleData input{
    /* border: 1px solid black; */
    border-radius: 5px;
    width: 100%;
    /* height: 30px; */
    font-size: clamp(12px, 1.2vw, 15px) !important;
}
.SingleData select{
    width: 100%;
    height: 30px;
    border-radius: 5px;
}
.dataSide {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 20%));
  grid-column-gap: 3%;
  grid-row-gap: 15px;
  height: min-content;
  justify-content: center;
  align-items: flex-start;
}

/* .dataSide .SingleData.rolesField {
  grid-column: span 2;
} */
.secondContainer,.thirdContainer,.fourthContainer{
  background-color: #120e4d03;
}
.collapseContainer{
    /* height: min-content; */
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(230px, 20%));
    grid-column-gap: 3%;
    grid-row-gap: 15px;
}
.MuiTabs-scroller {
  overflow: auto !important;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for WebKit-based browsers (Chrome, Safari) */
}

.MuiTabs-scroller::-webkit-scrollbar {
  display: none;
}

.header h6{
  margin-bottom: 0;
  line-height: 1.5rem;
}
@media screen and (max-width: 700px) {
  .firstContainer{
    flex-wrap: wrap;
    gap: 30px;
  }
  .image-uploader{
    width: 150px;
    height: 150px;
    gap: 10px;
  }
  .upload-image-container{
    width: 110px;
    height: 110px;
  }
  .selected-image-container{
    margin-bottom: 0;
    width: 100px;
    height: 100px;
  }
  .containerCollapse{
    gap: 12px;
  }
}
@media screen and (max-width: 500px) {
  .collapseContainer{
    /* height: min-content; */
    justify-content: flex-start;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(100%, 20%));
    grid-column-gap: 3%;
    grid-row-gap: 15px;
}
.dataSide {
  grid-template-columns: 1fr;
}
.dataSide .SingleData.rolesField {
  grid-column: 1;
}
.p-toast-message{
  max-width: 75% !important;
  padding-left: 10px !important;
}

}