@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.empContainer{
    width: 100%;
    background-color: #5853b72e;
    padding: 0px 20px 0 20px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}
Link{
    text-decoration: none;
}
.empContainer td,th{
    padding: 5px 20px;
    /* font-size: clamp(12px, 1.6vw, 17px); */
    white-space: nowrap;
}
.activeTd{
    display: flex;
}
.tbConatiner tr{
    height: 40px;
}
td,th{
    white-space: nowrap;
    padding: 0 20px;
}
::-webkit-scrollbar {
    width: 12px; /* Width of the entire scrollbar */
  }
  
::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 6px; /* Rounded corners of the scrollbar handle */
  }
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle on hover */
  }

.dropdown-toggle{
    background-color: #120e4d;
}
.empHeader{
    padding: 0.5rem;
}
.empFooter{
    padding: 0px 30px;
}
.empFooter p{
    font-size: clamp(12px, 1.4vw, 14px);
}
.empContainer td,.empFooter{
    font-weight: 600;
    cursor: pointer;
}
.empContainer th{
    /* color: #ffffff; */
    font-weight: 700;  
    /* background-color: #120e4d; */
}

.empContainer table {
    border-collapse: collapse;
    width: 100%;
}

.empContainer td {
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.empContainer th:first-child {
    border-radius: 10px 0 0 10px; /* Rounded top-left corner */
}
table tr td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  
table tr td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
.empHeader h6{
    font-weight: 600;
}
.empContainer th:last-child {
    border-radius: 0 10px 10px 0; /* Rounded top-right corner */
}
.empContainer td {
    border: none; /* Remove borders for data cells */
}
.empContainer .counts{
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: black;
    /* border: 1px solid grey; */
}
tbody tr:hover{
    background-color: rgb(207, 227, 235);
}
.dropdown-item{
    margin-bottom: 0;
}
.paginationCount {
    gap: 1rem;
}
@media screen and (max-width:700px) {
    .empContainer td,th{
        padding: 5px 10px;
        font-weight: 500;
    }
    .empHeader{
        padding: 10px 0;
    }
    .empContainer{
        padding: 0 10px;
        overflow: auto;
    }
    .empContainer th{
        font-weight: 500;  
    }
    .paginationCount {
        gap: 5px;
    }
    .empFooter{
        padding: 0;
    }
    .empContainer .counts{
        height: 20px;
        width: 20px;
        font-size: 10px;
    }
    .hideData{
        display: none;
    }
    .tbConatiner tr{
        height: 35px;
    }
}