@media screen and (max-width: 500px) {
    .loginContainer{
        align-items: start !important;
        /* justify-content: flex-start !important; */
        /* margin-top: 100px; */
        padding-top: 100px;
    }
    .Logincontent{
        border: 0 !important;
        border-radius: 0 !important;
        min-height: calc(100vh - 100px);
        box-shadow: none !important;
    }
}