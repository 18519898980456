.DetailsContainer .MuiBox-root .css-19kzrtu{
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  flex-wrap: wrap;
  gap: 1%;
  row-gap: 10px;
  /* padding: 10px; */
  margin-top: 15px;
}
.MuiButtonBase-root {
  padding: 5px 10px !important;
}
/* .personal-detail, .contact-detail, .job-detail{

} */

.profileInfo p,
.personalContainer p,
.FamilyContainer p {
  margin: 0;
  font-size: clamp(8px, 1vw, 10px);
  font-weight: 500;
  color: grey;
}
.profileInfo {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #5853b72e;
  min-width: 280px;
  width: 20%;
  height: min-content;
}
.personalContainer {
  width: 80%;
  height: min-content;
}

.personalDetails,
.DeptDetails,
.jobDetails,
.FamilyDetails {
  background-color: #5853b70d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* .mainDetails{
  background-color: #5853b70d;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
} */
.columns {
  width: 48%;
}
.DetailsContainer .columns h6{
  font-size: clamp(14px, 1.3vw, 16px);
}
.modal-body{
  padding: 10px 40px;
}
.modal-body label {
  font-size: 12px;
  font-weight: 600;
  /* margin-bottom: 5px; */
}
.DetailsContainer .modal-body input {
  height: 35px;
  margin-bottom: 15px;
}
.personalDetails,
  .DeptDetails,.jobDetails,
  .FamilyDetails {
    width: 50%;
  }
  .mainDetails{
    width: 100%;
  }
  .mainDetails .col{
    min-width: 150px;
  }
  .FamilyDetails{
    min-width: 330px;
  }
@media screen and (max-width: 1300px) {
  .personalContainer, .FamilyContainer {
    flex-direction: column;
  }
  .personalDetails,
  .DeptDetails,.jobDetails,
  .FamilyDetails {
    width: 100%;
  }
}
@media screen and (max-width:850px) {
  /* .mainDetails .mainIcon{
    width: 30px;
    height: 30px;
  } */
  /* .mainDetails .col{
    gap: 10px;
  } */
  /* .displayContainer{
    
  } */
}
@media screen and (max-width: 700px) {
  .DetailsContainer{
    flex-direction: column;
  }
  .profileInfo{
    width: 100%;
  }
  .mainDetails .col{
    min-width: auto;
  }
  .modal-body{
    padding: 10px 30px;
  }
}
@media screen and (max-width: 400px) {
  /* .profileInfo{
    width: 95%;
    min-width: 250px;
  } */
  .FamilyDetails{
    min-width: 280px;
  }
  .DetailsContainer .MuiBox-root .css-19kzrtu{
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: repeat(auto-fit, minmax(150px,1fr));
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 10px;
    /* padding: 10px; */
    margin-top: 15px;
  }
  .profileInfo{
    border-radius: 0 !important;
  }
}
