.offcanvas-body .accordion-item,
.sideBar .accordion-item {
  width: 100%;
  border: none;
}
.offcanvas-body .button_Container,
.sideBar .button_Container {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  min-height: 50px;
  /* overflow: hidden; */
}
.offcanvas-body .button_Container:hover,
.sideBar .button_Container:hover {
  color: white;
  background-color: #120e4d;
}
.offcanvas-body .button_Container:hover .icons,
.sideBar .button_Container:hover .icons {
  color: white;
}
.lni-chevron-down{
  color: black;
}
.offcanvas-body .button_Container:hover .lni-chevron-down,
.sideBar .button_Container:hover .lni-chevron-down {
  color: white;
}
.offcanvas-body .accordion-button:not(.collapsed),
.sideBar .accordion-button:not(.collapsed) {
  color: rgb(255, 255, 255);
  background-color: transparent;
  box-shadow: none;
}
.offcanvas-body .icons,
.sideBar .icons {
  /* width: 30%; */
  width: 60px;
  /* display: flex; */
  /* justify-content: start; */
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}
.offcanvas-body .icon-text,
.sideBar .icon-text {
  width: 50%;
}
.offcanvas-body .accordion-button,
.sideBar .accordion-button {
  height: 40px;
  background-color: transparent;
  color: white;
  padding: 0;
  width: 20%;
}
.offcanvas-body .accordion-body button,
.sideBar .accordion-body button {
  height: 40px;
  background-color: #ffffff;
  width: 100%;
  border: none;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  padding-left: 23%;
  justify-content: center;
  align-items: flex-start;
}
.offcanvas-body .accordion-body a,
.sideBar .accordion-body a{
  text-decoration: none;
  color: inherit;
}
.offcanvas-body .accordion-body button:hover,
.sideBar .accordion-body button:hover {
  background-color: #120e4d;
  color: #ffffff;
}
.offcanvas-body .accordion-body,
.sideBar .accordion-body {
  padding: 0;
}
.offcanvas-body .accordion-button:focus,
.sideBar .accordion-button:focus {
  box-shadow: none;
}
.offcanvas-body .accordion-flush,
.sideBar .accordion-flush {
  padding-top: 59px;
  transition: 0.6s ease-in-out;
}
.offcanvas-body .accordion-button::after,
.accordion-button::before,
.sideBar .accordion-button::after,
.accordion-button::before {
  content: none !important;
}
.sideBar {
  background-color: #fefeff;
  height: 100%;
  min-height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.6s ease-in-out;
  z-index: 10;
}
.sideBar .accordion-item {
  position: relative;
}
.sideBar .button_Container {
  position: relative;
}

.sideBar .dropdown {
  position: static;
}

.sideBar .dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: 0;
  margin-bottom: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.sideBar .accordion-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* Adjust existing dropdown styles */
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
}

/* Ensure the entire button area is clickable */
.sideBar .button_Container > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.dropdown-menu.show {
  display: flex;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* align-items: flex-start;
    position: absolute;
    left: 250px;
    top: -35px; */
}

@media screen and (max-width: 1000px) {
  .sideBar {
    display: none;
  }
}
