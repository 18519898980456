.MainGraphs,.MainTasks{
    width: 50%;
}
.MainGraphs{
    padding: 10px 25px;
}
.graphContainer p{
    margin-bottom: 0;
}
.overviewHeader p, .overviewHeader h6{
    margin-bottom: 0;
}
.overviewBody{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 100%;
    justify-content: space-around;
}
.overviewPercentage{
    font-weight: 600;
}
.MainGraphs p{
    font-size: clamp(13px, 1.3vw, 15px);
    font-weight: 500;
}
.projectCount{
    font-size: clamp(15px, 1.7vw, 24px);
    font-weight: 700;
}
.taskConatiner th{
    font-weight: 600;
    font-size: 15px;
}
.taskConatiner td{
    font-size: clamp(13px, 1.3vw, 15px);
    padding: 5px 20px ;
    font-weight: 500;
    border-bottom: none !important;
    cursor: pointer;
}
.taskConatiner tr:hover{
    background-color: rgb(250, 237, 173);
}
th:last-child {
    border-radius: 0 7px 7px 0;
}
th:first-child {
    border-radius: 7px 0 0 7px; 
}
.taskFooter button{
    font-size: clamp(12px, 1.3vw, 15px);
}
.skeleton-loader {
    background-color: #f0f0f0;
    border-radius: 4px;
    height: 20px;
    animation: pulse 1.5s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
  

@media screen and (max-width: 700px) {
    .taskConatiner {
        flex-wrap: wrap-reverse;
    }
    .MainGraphs,.MainTasks{
        width: 100%;
    }
    td{
        font-size: 15px;
        padding: 5px 10px ;
        font-weight: 500;
        border-bottom: none !important;
        cursor: pointer;
    }
}